<template>
  <div class="row" v-if="user">
    <div class="col-md-3">
      <div class="box box-primary">
        <div class="box-body box-profile">
          <template v-if="user.avatar">
            <ui-avatar :src="`${baseUrl}/${user.avatar}`"
                       class="profile-user-img img-responsive img-circle"/>
          </template>
          <template v-else>
            <ui-avatar src="default.png"
                       class="profile-user-img img-responsive img-circle"/>
          </template>
          <h3 class="profile-username text-center">{{ user.first_name }} {{ user.last_name }}</h3>
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <strong>
                <i class="fas fa-map-marker-alt margin-r-5"></i>
                {{ $t('string.location') }} : <span class="text-muted">{{ user.address || $t('string.na') }}</span>
              </strong>

            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiAvatar from '@/views/admin/_components/UiAvatar'

export default {
  name: 'Layout',
  components: { UiAvatar },
  data () {
    return {
      apiUrl: process.env.VUE_APP_API
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  methods: {
    ...mapActions([
      'setToken'
    ])
  }
}
</script>

<style scoped>

</style>
